import React, { useState } from "react"
import './css/table.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faArrowUpRightFromSquare,
    faCheckCircle,
    faXmark
} from '@fortawesome/free-solid-svg-icons'
import { faXmarkCircle } from '@fortawesome/free-regular-svg-icons'





const ExpandingDiv = (props) => {
    const [show, setShow] = useState(false);

    return (
        <div className="expandingDiv">
            <button className="expandingButton" onClick={()=>setShow(!show)}>
                {show ? <FontAwesomeIcon style={{color:"#ed5656"}} icon={faXmark}/> : <FontAwesomeIcon style={{color:"#5468b7"}} icon={faArrowUpRightFromSquare}/> }
            </button>
            <div className="expandingContent" style={{width: show && props.content !== null ? "250px" : "0px"}}>
                {props.content}
            </div>
        </div>
        
    )
}


function Table ({head, body}) {


    const removeDash = (str) => {
        var newString = str.replace(/_/g," ");
        return newString;
    }

    const globalChecker = (val, col) => {
        if (col === "opt" || col === "redeemed" || col === "sent") {
            return checkmark(val);
        }
        else if(col === "expired") {
            return checkexp(val);
        }
        else if(col === "code") {
            return (
                <div className="label codeStyle">{val}</div>
            )
        }
        else if (col === "text_message_proof") {
            return <ExpandingDiv content={val}/>;
        }
        else if(col === "id" || col === "clientID" || col === "date_created"){
            return <ExpandingDiv content={val}/>;
        }
        return val;
    }



    const checkmark = (e) => {
        if(e === true){
            return <FontAwesomeIcon icon={faCheckCircle} /> 
        }
        else if( e === false){
            return <FontAwesomeIcon icon={faXmarkCircle}/>
        }
    }

    
    const checkexp = (e) => {
        if(e === true){
            return <div className="label expire">expired</div> 
        }
        else if( e === false){
            return <div className="label ok">ok</div> 
        }
    }

    return body.length === 0 ? (
        <div className="noMatch">
            {"No Match :("}
        </div>
    ) : (
        <table className="Dtable">
            <thead>
                <tr>
                    {head.map((heading) => (<th key={heading}>{removeDash(heading)}</th>) )}
                </tr>
            </thead>
            <tbody>
                {body.map((row, index) => (
                    <tr key={index}>
                        {head.map((col) => (
                            <td key={col}>{globalChecker(row[col], col)}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
            
        </table>
    )
}

export default Table;