import React, { useCallback, useEffect, useState } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { Promotions } from '../models';
import { Authenticator } from '@aws-amplify/ui-react';
import './css/SMSForm.css';



function SMSForm () {
  const [inputs, setInputs] = useState({});
  const [checkAll, setCheckedAll] = useState(true);
  const [message, setMessage] = useState("")
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(options[0]);


  const onHandleChange = (event) => {
    event.preventDefault();
    const name = event.target.name;
	  const value = event.target.value;
	  setInputs(values => ({...values, [name]: value}))
  };

   /** Get the promo list to populate table. */
  const fetchPromotions = useCallback(async() => {
    try {
      const promos = await DataStore.query(Promotions);
      await getPromoInfo(promos);
    }
    catch(e){
        console.log(e);
    }
  },[])

  const onSelect = async (event) => {
    event.preventDefault();
    const name = event.target.value;
    setSelected(name);
    await populate(name)
   
  };

  const populate = async (name) => {
    try {
      const promos = await DataStore.query(Promotions, (promo) =>  promo.name("eq", name));
      setMessage(promos[0].message);
      setInputs(values => ({...values, "message": promos[0].message}))

    }
    catch(e){
        console.log(e);
    }
  }


  const getPromoInfo = (promos) => {
    var promolist = []
    promos.forEach(el => {
      promolist.push(el.name);
    });
    setOptions(promolist);
  }

  const onSubmit = (event) => {
    event.preventDefault();

    fetch('/api/messages', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(inputs)
    }).then((res) => {
      if (res.status === 200){
        alert(res.statusText)
        console.log(res)
        setInputs({});
      }
      else {
        alert(res.statusText)
      }
      
    }).catch((e) => console.log(e));
    
  };

  
  useEffect(()=> {
    fetchPromotions();

  },[fetchPromotions]);


  return (
    <Authenticator>
      <div className="sms-container">
        <form onSubmit={onSubmit} className="form-div">
          <div className='sms-form-radio'>
            <input name="towho" id="all" type="radio" onClick={()=> setCheckedAll(true)}/>
            <label htmlFor="all">All Client</label>
            <input name="towho" id="single" type="radio" onClick={()=> setCheckedAll(false)}/>
            <label htmlFor="single">Single Client</label>
          </div>
          <div>
          <select 
              value={selected}
              onChange={onSelect}>
                  {options.map((value) => (
                      <option value={value} key={value}>
                          {value}
                      </option>
                  ))}
            </select>
          </div>
          <div style={{display: checkAll ? "none" : "inline"}}>
            <input
              type="tel"
              placeholder="8889991111"
              name="to"
              value={inputs.to || ""}
              onChange={onHandleChange}
            />
          </div>
          <div>
            <textarea
              placeholder="Message"
              name="message"
              rows={5}
              value={message || ""}
              onChange={(e)=>setMessage(e.target.value)}
            />
          </div>
          <button type="submit">Send message</button>
        </form>
      </div>
    </Authenticator>

  );
  
}

export default SMSForm;