// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Keys, Coupon, Promotions, Client } = initSchema(schema);

export {
  Keys,
  Coupon,
  Promotions,
  Client
};