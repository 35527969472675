import React from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { NavLink } from "react-router-dom";
import './css/sidenav.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-regular-svg-icons";

const SideNav = (props) => {
	const { authStatus } = useAuthenticator(context => [context.authStatus]);
	const { signOut, user } = useAuthenticator((context) => [context.user]);

	const acl = props.acl;
	
	return authStatus === "authenticated" ? (
		<div className="side-nav-container">
			<nav>
				<div className="user-div">
					<div className="ico">
						<FontAwesomeIcon icon={faCircleUser}/>
					</div>
					<div className="username">
						Hiya {user.attributes.preferred_username}
					</div>
				</div>
				<ul className="side-nav-ul">
					{acl.map((item) => (
						<NavLink 
							key={item.id}
							to={item.link}
							className="side-nav-item">
							<i className={item.ico}/>
							<div className="side-nav-text">{item.value}</div>
						</NavLink>
					))}
					<button
						onClick={signOut}
						className="side-nav-item signout">
						<i className="fa-solid fa-right-from-bracket"></i>
						<div className="side-nav-text">Sign Out</div>
					</button>
				</ul>
			</nav>
		</div>
	)
	:
	<></>
	
}

export default SideNav;