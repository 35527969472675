import React, { useEffect } from 'react';
import './css/portal.css'
import { Authenticator, View, useTheme, Image, Text, useAuthenticator, Button} from '@aws-amplify/ui-react';
import SideNav from '../components/SideNav'
import { Outlet } from 'react-router-dom';
import { DataStore } from 'aws-amplify';

function Portal() {
	const { authStatus } = useAuthenticator(context => [context.authStatus]);

  const acl = [
    {id:1, link:"/", value:"Home", ico:"fa-solid fa-house", param:{}},
    {id:2, link:"/clientpool", value:"Client Pool", ico:"fa-solid fa-users-gear", param:{}},
    {id:3, link:"/couponpool", value:"Coupon", ico:"fa-solid fa-barcode", param:{}},
    {id:4, link:"/promotion", value:"Promotions", ico:"fa-solid fa-tag", param:{}},
    // {id:5, link:"/sms", value:"SMS Service", ico:"fa-solid fa-comment-sms", param:{}},

  ]

    const components = {
        Header() {
            const { tokens } = useTheme();
        
            return (
                <View textAlign="center" padding={tokens.space.xxl}>
                    <Image
                        alt="Deviit Management System Logo for Login Page"
                        src="/images/deviit-logo.svg"
                        width="250px"
                        paddingTop="50px"
                    />
                </View>
            );
        },

        Footer() {
            const { tokens } = useTheme();
        
            return (
              <View textAlign="center" padding={tokens.space.large}>
                <Text color={tokens.colors.neutral[80]}>
                  &copy; All Rights Reserved
                </Text>
              </View>
            );
        },
        
        SignIn: {
            Footer() {
              const { toResetPassword } = useAuthenticator();
        
              return (
                <View textAlign="center">
                  <Button
                    fontWeight="normal"
                    onClick={toResetPassword}
                    size="small"
                    variation="link"
                    backgroundColor="color"
                    >
                    Reset Password
                  </Button>
                </View>
              );
            },
        }
    }

    const signIn = async () => {

      try{
        await DataStore.start();
        console.log("DataStore starting...");
      }
      catch(e){
        console.log(e);
      }
    }

	
    useEffect (()=> {
		  signIn();

    },[signIn])
    

    return (
		<Authenticator components={components} hideSignUp={true}>
			<div className='portal-container'>
				{authStatus === 'authenticated' ? <SideNav acl={acl}/> : "" }
				<Outlet />
			</div>
		</Authenticator>
  );
}

export default Portal;