import React, { useCallback, useEffect, useState } from 'react';
import { DataStore,Predicates } from '@aws-amplify/datastore';
import { Coupon } from '../models';
import { Authenticator } from '@aws-amplify/ui-react';
import Table from '../components/Table';
import './css/couponpool.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faArrowsRotate, faReceipt } from '@fortawesome/free-solid-svg-icons'

function CouponPool() {
    const [inputs, setInputs] = useState({});
    const [couponList, setCouponList] = useState([]);
    const [date, setDate] = useState(new Date())
    const heading = ["code","start_date","end_date","redeemed", "expired", "redeemed_date", "clientPhoneNumber", "sent", "text_message_proof", "clientID","date_created"]
    const [loading, setLoading] = useState(false);
    const options = ["client phone #", "code"];
    const [selected, setSelected] = useState(options[0]);
    const [caller, setCaller] = useState("");

    /** Get the promo list to populate table. */
    const fetchCoupons = useCallback(async() => {
        try {
            DataStore.observe(Coupon)
            const coupons = await DataStore.query(Coupon, Predicates.ALL, {
                sort:  s => s.end_date('ASCENDING')
            });
            setCouponList(coupons);
        }
        catch(e){
            console.log(e);
        }
    },[])
    

    /** Handle the change within input with each key. */
    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({...values, [name]: value}))
    }



    const redeemCoupon = async (event) =>{
        event.preventDefault();
        let alertmessage = "{}";

        try {

            const confirmM = ("Are you sure you want to redeem coupon code: " + inputs.code.toUpperCase());
            const original = await DataStore.query(Coupon, coupon =>  coupon.code("eq", inputs.code.toUpperCase()));
            
            if (original.length !== 0 ){
                console.log(original)
                if (original[0].redeemed === false && original[0].expired === false){
                    if (window.confirm(confirmM)){

                        await DataStore.save(Coupon.copyOf(original[0], updated => {
                            updated.redeemed = true
                            updated.redeemed_date = date.toJSON()
                        })
                        ).then((res)=> {
                            console.log(res);
                        });
                        fetchCoupons();
                        alertmessage = "Code " + inputs.code.toUpperCase() + " successfully redeemed";
                        setInputs({})
                    }
                    else {
                        alertmessage = "Redeem Canceled"
                    }
                }
                else {
                    alertmessage = "ALERT!\nCoupon code " + inputs.code.toUpperCase() + " already used or expired.\nUsed On: " + (original[0].redeemed === false ? "" : original[0].redeemed_date) + "\nExpired On: " + original[0].end_date;
                }
            }
            else {
                alertmessage = "Coupon code " + inputs.code.toUpperCase() + " doesn't exist."
            }

            alert(alertmessage);
        }
        catch(e){
            console.log(e);
        }
        window.location.reload();
    }

    /** Handle form submission. */
    function handleSubmit (event) {
        event.preventDefault();

        if(caller === "redeem"){
            setLoading(true)
            setTimeout(() => {
                setLoading(false)
                redeemCoupon(event);
            }, 1000)
        }
        fetchCoupons();
        console.log(event.target.value)

    }
    

    const expireCheck = useCallback(async() => {

        try {
            var dDateOnly = new Date(date.getFullYear(),date.getMonth(),date.getDate()-1);

            const list = await DataStore.query(Coupon, (cli) => cli.end_date('lt', dDateOnly.toJSON()))
            
            list.forEach( async (coupon) => {
                await DataStore.save(Coupon.copyOf(coupon, updated => {
                    updated.expired = true;
                }));
            })

        }
        catch(e){
            console.log(e);
        }
    },[]);


    const handleSearch = async (event) => {
        event.preventDefault();

            try {
                if (selected === "code" ){
                    await DataStore.query(Coupon, (cli) => cli.code('beginsWith', inputs.search)).then((res)=> {
                        setCouponList(res);
                        console.log(res)
                    })
                }
                else if (selected === "client phone #"){
                    await DataStore.query(Coupon, (cli) => cli.clientPhoneNumber('beginsWith', inputs.search)).then((res)=> {
                        setCouponList(res);
                        console.log(res)
                    })
                }
            }
            catch(e){
                console.log(e);
            }
        
    }


    useEffect(()=> {
        var timer = setInterval(() => setDate(new Date()), 90000)
        fetchCoupons();
        expireCheck();
        
        return function cleanup(){
            clearInterval(timer)
        }

    },[fetchCoupons, expireCheck]);
    
    return (
        <Authenticator>
            <div className='coupon-pool-container'>
                <div className='portal-header'>
                    {"Coupons"}
                    <button className='refresh-button' onClick={fetchCoupons}>
                        <FontAwesomeIcon icon={faArrowsRotate}/>
                    </button>
                </div>
                <br/>
                <div className='coupon-pool-form-div'>
                    <form className='coupon-pool-form' id="couponform" onSubmit={handleSubmit}>
                        <div className="form-ico-div">
							<h6>Redeem Coupon</h6>
							<div className='form-ico'>
								<FontAwesomeIcon icon={faReceipt}/>
							</div>
						</div>
                        <label><strong>*</strong>Coupon code
                            <input
                                type="text" 
                                name="code" 
                                value={inputs.code || ""} 
                                onChange={handleChange}
                                required
                            />
                        </label>
                        <button
                            disabled={loading}
                            type="submit" 
                            className='redeem' 
                            name="submittype" 
                            value="Redeem Coupon"
                            form="couponform"
                            onClick={()=>setCaller("redeem")}>
                            {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Redeem Coupon"}
                        </button>
                    </form>
                </div>
                <div className='search-div'>
                    <select 
                        value={selected}
                        onChange={e => setSelected(e.target.value)}>
                            {options.map((value) => (
                                <option value={value} key={value}>
                                    {value}
                                </option>
                            ))}
                    </select>
					<input
						type="text" 
						name="search"
						value={inputs.search || ""}
						onChange={handleChange}
						placeholder='search'
					/>
					<button onClick={handleSearch}>
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </button>
				</div>
                <div className='table-div'>
                    <Table head={heading} body={couponList}/>
                </div>
            </div>
        </Authenticator>
    )
}

export default CouponPool;