import React, { useCallback, useEffect, useState } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { Client, Promotions } from '../models';
import { Authenticator } from '@aws-amplify/ui-react';
import './css/promotionPool.css'
import Table from '../components/Table';
import { faPenToSquare, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



function PromotionPool() {
    const [inputs, setInputs] = useState({});
    const [promotionList, setPromotionList] = useState([]);
    const heading = ["name","start_date","end_date","message"];

	const [loading, setLoading] = useState(false);
	const [caller, setCaller] = useState("");

    /** Get the promo list to populate table. */
    const fetchPromotions = useCallback(async() => {
        try {
            const promos = await DataStore.query(Promotions);
            const clients = await DataStore.query(Client);

            setPromotionList(promos);
            await getPromoInfo(promos, clients);
        }
        catch(e){
            console.log(e);
        }
    },[])
    

    /** Handle the change within input with each key. */
    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({...values, [name]: value}))
    }


    /** Add promo. Check for replicated phone numbers  */
    const addPromotions = async (event) => {
        event.preventDefault();
        let alertmessage = "{}";

        try {
                const res = await DataStore.query(Promotions, (promo) =>  promo.name("eq", inputs.name));

                if (res.length === 0){
                    await DataStore.save(
                        new Promotions({
                            "name": inputs.name,
                            "message": inputs.message,
                            "start_date": inputs.start_date+"Z",
                            "end_date": inputs.end_date+"Z",
                        })
                    )
                    alertmessage = "Promotion: " + inputs.name + " was added.";
                    setInputs({})
                }
                else {
                    alertmessage = "Promotion :" + inputs.name + " already exists";
                }

                fetchPromotions();
                alert(alertmessage);
        }
        catch(e){
            console.log(e);
        }
        finally{
            window.location.reload();
        }
    }


    /** Updated client. Query by phone# */
    const updatePromotions = async (event) => {
        event.preventDefault();
        let alertmessage = "{}";

        try {

            const confirmM = ("Is all the information correct?\nName: "+inputs.name+"\nStart Date: "+inputs.start_date+"\nEnd Date: "+ inputs.end_date+"\nMessage: "+inputs.message);
            const original = await DataStore.query(Promotions, (promo) =>  promo.name("eq", inputs.name));

            if (original.length !== 0 ){
                if (window.confirm(confirmM)){
                    await DataStore.save(Promotions.copyOf(original[0], updated => {
                            updated.name = inputs.name === undefined || inputs.name === "" ? original[0].name : inputs.name
                            updated.message = inputs.message === undefined || inputs.message === "" ? original[0].message : inputs.message
                            updated.start_date = inputs.start_date === undefined || inputs.start_date === "" ? original[0].start_date : inputs.start_date+"Z"
                            updated.end_date = inputs.end_date === undefined || inputs.end_date === "" ? original[0].end_date : inputs.end_date+"Z"
                        })
                    ).then((res)=>{
                        console.log(res)
                    });
                    fetchPromotions();
                    alertmessage = "Promotion: " + inputs.name + " was added.";
                    setInputs({})
                }
                else {
                    alertmessage = "Update Canceled"
                }
            }
            else {
                alertmessage = "Promotion doesn't exist."
            }

            alert(alertmessage);
        }
        catch(e){
            console.log(e);
        }
        finally{
            window.location.reload();
        }
    }


    /** Remove client. Query by phone# */
    const removePromotions = async (event) => {        
        event.preventDefault();
        let alertmessage = "{}";

        try {
            const confirmM = "WARNING!\nAbout to delete promotion with name [ " + inputs.name + " ]\nClick OK to delete";

            if (window.confirm(confirmM)){
                await DataStore.delete(Promotions, (promo) => promo.name("eq", inputs.name))
                alertmessage = "Promotion: " + inputs.name + " was deleted.";
                setInputs({})
            }
            else {
                alertmessage = "Delete Client cancel"
            }

            fetchPromotions();
            alert(alertmessage);
        }
        catch(e) {
            console.log(e);
        }
        finally{
            window.location.reload();
        }
    }



    /** Handle form submission. */
    function handleSubmit (event) {
        event.preventDefault();
        
        if (caller === "add"){
            setLoading(true)
			setTimeout(() => {
				setLoading(false)
                addPromotions(event);
			}, 2000)
            console.log(caller)
        } 
        else if (caller === "remove" ){
            setLoading(true)
            setTimeout(() => {
				setLoading(false)
                removePromotions(event);
			}, 2000)
            console.log(caller)

        }
        else if (caller === "update"){
            setLoading(true)
            setTimeout(() => {
				setLoading(false)
                updatePromotions(event);
			}, 2000)
            console.log(caller)

        }
        fetchPromotions();
    }

    const getPromoInfo = (promos, clients) => {
        var namelist = []
        var phonelist = []
        promos.forEach(el => {
            namelist.push(el.name);
        });
        clients.forEach(el => {
            phonelist.push(el.phone_number);
        })
    }

    useEffect(()=> {
        fetchPromotions();

    },[fetchPromotions]);
    
    return (
        <Authenticator>
            <div className='promo-pool-container'>
                <div className='portal-header'>
                    {"Promotions"}
                </div>
                <br/>
                <div className='promo-pool-form-div'>
                    <form className='promo-pool-form' id="form-promo" onSubmit={handleSubmit}>
                        <div className="form-ico-div">
							<h6>Edit Promotions</h6>
							<div className='form-ico'>
								<FontAwesomeIcon icon={faPenToSquare}/>
							</div>

						</div>
                        <label><strong>*</strong>Name
                            <input
                                type="text" 
                                name="name" 
                                value={inputs.name || ""} 
                                onChange={handleChange}
                                required
                            />
                        </label>
                        <label><strong>*</strong>Start Date
                            <input
                                type="text"
                                required
                                name="start_date" 
                                value={inputs.start_date || ""} 
                                onChange={handleChange}
                                pattern="\d{4}-\d{2}-\d{2}"

                            />
                        </label>
                        <label><strong>*</strong>End Date
                            <input 
                                required
                                type="text"
                                name="end_date" 
                                value={inputs.end_date || ""} 
                                onChange={handleChange}
                                pattern="\d{4}-\d{2}-\d{2}"
                                />
                        </label>
                            <label><strong>*</strong>Message
                                <textarea
                                    rows="8"
                                    cols="50"
                                    name="message"
                                    value={inputs.message || ""}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                            <div className='button-group'>
                                <button
                                    disabled={loading}
                                    type="submit"
                                    className='add' 
                                    name="submittype" 
                                    value="Add Promo"
                                    form="form-promo"
                                    onClick={()=>setCaller("add")}>
                                    {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Add Promo"}
                                </button>
                                <button
                                    disabled={loading}
                                    type="submit" 
                                    className='remove' 
                                    name="submittype" 
                                    value="Remove Promo"
                                    form="form-promo"
                                    onClick={()=>setCaller("remove")}>
                                    {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Remove Promo"}
                                </button>
                                <button
                                    disabled={loading}
                                    type="submit" 
                                    className='update' 
                                    name="submittype" 
                                    value="Update Promo"
                                    form="form-promo"
                                    onClick={()=>setCaller("update")}>
                                    {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Update Promo"}
                                </button>
						</div>
                    </form>
                </div>

                <div className='table-div'>
                    <Table head={heading} body={promotionList}/>
                </div>

            </div>
        </Authenticator>
    )
}

export default PromotionPool;