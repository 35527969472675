import React, { useCallback, useEffect, useState } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { Predicates } from '@aws-amplify/datastore';
import { Client } from '../models';
import { Coupon } from '../models';
import { Authenticator } from '@aws-amplify/ui-react';
import './css/clientpool.css'
import Table from '../components/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faUserPen } from '@fortawesome/free-solid-svg-icons'

function ClientPool() {
	const [inputs, setInputs] = useState({});
	const [date, setDate] = useState(new Date());
	const [clientList, setClientList] = useState([]);
	const heading = ["phone_number","first_name","last_name", "birth_month", "birth_day", "id", "date_created", "app"]
	const [loading, setLoading] = useState(false);
	const [caller, setCaller] = useState("");

	const optionMonth = ["Jan ( 1 )", "Feb ( 2 )", "March ( 3 )", "April ( 4 )", "May ( 5 )", "June ( 6 )", "July ( 7 )", "Aug ( 8 )", "Sep ( 9 )", "Oct ( 10 )", "Nov ( 11 )", "Dec ( 12 )"];
    const optionDay = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12","13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"];
    const [selectedMonth, setSelectedMonth] = useState(optionMonth[0]);
    const [selectedDay, setSelectedDay] = useState(optionDay[0]);



	/** Handle the change within input with each key. */
	const handleChange = (event) => {
	  const name = event.target.name;
	  const value = event.target.value;
	  setInputs(values => ({...values, [name]: value}))
	}

	/** Get the client list to populate table. */
	const fetchClients = useCallback(async() => {
		try {
			const clients = await DataStore.query(Client, Predicates.ALL, {
				sort: s => s.date_created('DESCENDING')
			});
			setClientList(clients);
		}
		catch(e){
			console.log(e);
		}
	},[])

	const makeCode = (client) => {
		const randomCharacter = Math.random().toString(36).substring(2,10);
		var code = client.last_name === "" || client.last_name === undefined ? client.first_name[0] + randomCharacter : client.first_name[0] + client.last_name[0] + randomCharacter;

		return (code.toUpperCase());
	}


	const createExp = (year, month, day) => {
		var newDay = ( parseInt(day) + 14 );
		var newMonth = month;
		var newYear = year;

		if (month === 2){
			if (newDay >= 29 ) {
				newDay = newDay % 28;
				newMonth = month + 1;

			}
		}

		if (newDay >= 30) {
			newDay = newDay % 30;
			newMonth = month + 1;
		}

		if (newMonth > 12){
			newMonth = newMonth % 12;
			newYear = newYear + 1;
		}

		if (newDay === 0){
			newDay = 1;
		}

		return (newYear + "-" + String("0" + newMonth).slice(-2) + "-" + String("0" + newDay).slice(-2));
	}

	const getStartYear = (client) => {
		//Bc month starts at 0
		if (client.birth_month < (date.getMonth() + 1)) {
			return (date.getFullYear() + 1)
		}
		if (client.birth_month === (date.getMonth() + 1)) {
			if (client.birth_day <= date.getDate()) {
				return (date.getFullYear() + 1)
			}
		}
		return date.getFullYear();
	}

	/** Coupon require - clientID */
	const couponBuilder = async (client) => {
	   const code = makeCode(client);
	   const year = getStartYear(client);
	   const start_date = (year + "-" + String("0" + client.birth_month).slice(-2) + "-" + String("0" + client.birth_day).slice(-2));
	   const end_date = createExp(parseInt(year), client.birth_month, client.birth_day);
	   const message = ("10% OFF Birthday Discount - 5 Star Nail Spa. <br /><br /> Happy Birthday from us to you! 🎉 <br />Give your code at the register to receive 10% OFF on your total service! <br /><br />CODE - " + code + " <br /><br />*Cannot be combined with other coupons and/or promotional discounts. Discount valid from " + start_date + " - " + end_date + ".*")

	   try {
			await DataStore.save(
				new Coupon({
					"clientID": client.id,
					"redeemed": false,
					"start_date": start_date,
					"end_date":  end_date,
					"code": code,
					"date_created": date.toJSON(),
					"clientPhoneNumber": inputs.phone_number,
					"expired": false,
					"text_message_proof": message,
					"sent": false,

				})
			).then((res)=> {
				console.log(res);
			})
	   } 
	   catch (error) {
			console.log(error)
	   }
	   finally {
			try {
				DataStore.observe(Coupon);
			}
			catch(e) {
				console.log(e);
			}
	   }
	
	};



	/** Add client. Check for replicated phone numbers  */
	const addClient = async (event) => {
		event.preventDefault();
		let alertmessage = "{}";

		try {
				const res = await DataStore.query(Client, (client) =>  client.phone_number("eq", "+1"+inputs.phone_number));

				if (res.length === 0){
					await DataStore.save(
						new Client({
							"first_name": inputs.first_name,
							"last_name": inputs.last_name,
							"phone_number": "+1" + inputs.phone_number,
							"opt": true,
							"date_created": date.toJSON(),
							"birth_day": parseInt(selectedDay),
							"birth_month": parseInt(getMonthVal(selectedMonth))+1,
							"app": "login"

						})
					).then((res)=> {
						console.log(res);
						if(inputs.birth_day !== undefined || inputs.birth_day !== "" || inputs.birth_month !== undefined || inputs.birth_month !== ""){
							couponBuilder(res);
						}
					})
					alertmessage = "Client " + inputs.first_name + " with phone number " + inputs.phone_number + " was added.";
					alert(alertmessage);
					alert("A birthday coupon was made for " + inputs.first_name)

				}
				else {
					alertmessage = "The client with phone number " + inputs.phone_number + " already in your pool.\nTry Update client instead";
					alert(alertmessage);
				}
		}
		catch(e){
			alert("Please enter first name or correct phone number")
			console.log(e);
		}
		finally {
			fetchClients();
			setInputs({})
		}
	}


	/** Updated client. Query by phone# */
	const updateClient = async (event) => {
		event.preventDefault();
		let alertmessage = "{}";

		try {

			const confirmM = ("Is all the information correct?\nFirst name: "+inputs.first_name+"\nLast name: "+inputs.last_name+"\nPhone#: "+ inputs.phone_number+"\nBirthday: "+inputs.birth_month+"/"+inputs.birth_day);
			const original = await DataStore.query(Client, client =>  client.phone_number("eq", "+1"+inputs.phone_number));

			if (original.length !== 0 ){
				if (window.confirm(confirmM)){
					await DataStore.save(Client.copyOf(original[0], updated => {
							updated.first_name = inputs.first_name === undefined || inputs.first_name === "" ? original[0].first_name : inputs.first_name
							updated.last_name = inputs.last_name === undefined || inputs.last_name === "" ? original[0].last_name : inputs.last_name
							updated.phone_number = inputs.phone_number === undefined || inputs.phone_number === "" ? original[0].phone_number : "+1"+inputs.phone_number
							updated.birth_day = parseInt(selectedDay)
							updated.birth_month = parseInt(getMonthVal(selectedMonth))+1

						})
					).then((res)=> {
						console.log(res);
					});
					fetchClients();
					alertmessage = "Client " + inputs.first_name + " with phone number " + inputs.phone_number + " was updated.";
				}
				else {
					alertmessage = "Update Canceled"
				}
			}
			else {
				alertmessage = "Client doesn't exist."
			}
			alert(alertmessage);
		}
		catch(e){
			console.log("isk");
		}
		finally{
			setTimeout(window.location.reload(),1000);
		}
	}

	const removeCoupon = async (client) => {
		try {
			await DataStore.delete(Coupon, (coupon) => coupon.clientID("eq", client.id)
			).then((res) => console.log("coupon: " + res));
		}
		catch (err) {
			console.log(err);
		}
	}

	/** Remove client. Query by phone# */
	const removeClient = async (event) => {        
		event.preventDefault();
		let alertmessage = "{}";

		try {
			const confirmM = "WARNING!\nAbout to delete Client with phone number [ " + inputs.phone_number + " ]\nClick OK to delete";

			if (window.confirm(confirmM)){
				await DataStore.delete(Client, (cli) => cli.phone_number("eq", "+1"+inputs.phone_number)
				).then((res)=>{
					removeCoupon(res);
				})
				alertmessage = "Client " + inputs.first_name + " with phone number " + inputs.phone_number + " was deleted.";
				setInputs({})
			}
			else {
				alertmessage = "Delete Client cancel"
			}
			fetchClients();
			alert(alertmessage);

		}
		catch(e) {
			console.log(e);
		}
		finally{
			window.location.reload();
		}
	}

	
	const searchByPhone = async (event) => {
		event.preventDefault();

		try {
			await DataStore.query(Client, (cli) => cli.phone_number("beginsWith", "+1"+inputs.search)
			).then((res)=>{
				setClientList(res);
				console.log(res)
			});
		}
		catch(e){
			console.log(e);
		}
	}

	/** Handle form submission. */
	function handleSubmit (event) {
		event.preventDefault();

		if (caller === "add"){
			setLoading(true)
			setTimeout(() => {
				setLoading(false)
				addClient(event);
			}, 2000)
		} 
		else if (caller === "remove" ){
			setLoading(true)
			setTimeout(() => {
				setLoading(false)
				removeClient(event);
			}, 2000)
		}
		else if (caller === "update"){
			setLoading(true)
			setTimeout(() => {
				setLoading(false)
				updateClient(event);
			}, 2000)
			
		}
		console.log(event.target.value)
		fetchClients();
	}

	const getMonthVal = (monthString) => {
        return optionMonth.indexOf(monthString);
    }

	useEffect(()=> {
		var timer = setInterval(() => setDate(new Date()), 3000)
		fetchClients();

		return function cleanup(){
			clearInterval(timer)
		}
	},[fetchClients]);

	
	return (
		<Authenticator>
			<div className='clientpool-container'>
				<div className='portal-header'>
				{"Client Pool"}
				</div>
				<br/>
				<div className='clientpool-form-div'>
					<form className='clientpool-form' id="formclient" onSubmit={handleSubmit}>
						<div className="form-ico-div">
							<h6>Edit Client</h6>
							<div className='form-ico'>
								<FontAwesomeIcon icon={faUserPen}/>
							</div>
						</div>
						<label><strong>*</strong>First name
							<input
								type="text" 
								name="first_name" 
								value={inputs.first_name || ""} 
								onChange={handleChange}
								required
								placeholder=''
							/>
						</label>
						<label>Last name
							<input
								type="text" 
								name="last_name" 
								value={inputs.last_name || ""} 
								onChange={handleChange}
							/>
						</label>
						<label><strong>*</strong>Phone # (8007775555)
							<input 
								required
								type="tel"
								name="phone_number" 
								value={inputs.phone_number || ""} 
								onChange={handleChange}
								pattern="[0-9]{3}[0-9]{3}[0-9]{4}" 
							/>
						</label>
						<label>
                            Birthday Information
                            <div className='birthday-field'>
                                <select
                                    value={selectedMonth}
                                    onChange={e => setSelectedMonth(e.target.value)}>
                                        {optionMonth.map((value) => (
                                            <option value={value} key={value}>
                                                {value}
                                            </option>
                                        ))}
                                </select>
                                <hr/>
                                <select
                                    value={selectedDay}
                                    onChange={e => setSelectedDay(e.target.value)}>
                                        {optionDay.map((value) => (
                                            <option value={value} key={value}>
                                                {value}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </label>
							
						<div className='button-group'>
							<button
								disabled={loading}
								type="submit"
								className='add' 
								name="submittype" 
								value="Add Client"
								form="formclient"
								onClick={()=>setCaller("add")}>
								{loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Add Client"}
							</button>
							<button
								disabled={loading}
								type="submit" 
								className='remove' 
								name="submittype" 
								value="Remove Client"
								form="formclient"
								onClick={()=>setCaller("remove")}>
								{loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Remove Client"}
							</button>
							<button
								disabled={loading}
								type="submit" 
								className='update' 
								name="submittype" 
								value="Update Client"
								form="formclient"
								onClick={()=>setCaller("update")}>
								{loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Update Client"}
							</button>
						</div>
					</form>
				</div>
				<div className='search-div'>
					<i className="fa-solid fa-magnifying-glass"></i>
					<input
						type="text" 
						name="search"
						value={inputs.search || ""}
						onChange={handleChange}
						placeholder='search client by phone #'
					/>
					<button onClick={searchByPhone}>search</button>
				</div>

				<div className='table-div'>
					<Table head={heading} body={clientList}/>
				</div>
			</div>
		</Authenticator>
	);

}

export default ClientPool;