import React, { useCallback, useEffect, useState } from 'react' 
import './css/home.css'
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { DataStore } from 'aws-amplify';
import { Client, Coupon, Promotions } from '../models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsRotate, faBarcode, faReceipt, faTags, faUsers } from '@fortawesome/free-solid-svg-icons'
import { faCalendarCheck, faCircleUser } from '@fortawesome/free-regular-svg-icons';

const Cards = (props) => {
    const style = 'card-value ' + props.class;
    const iconStyle = 'icon-style ' + props.param.style;
    const cardstyle = 'card ' + props.param.cardStyle;

    return props.param.cardStyle === "link" ? 
    (
        <a style={{margin:"1%", textDecoration:"none"}} href={props.value} target="_blank" rel="noopener noreferrer">
            <div className={cardstyle}>
                <div>
                    <h6>{props.title}</h6>
                </div>
                <div className='card-body'>
                    {props.body}
                </div>
            </div>
        </a>
    )
    :
    (
        <div className="card">
            <div className="icon-div">
                <div className={iconStyle}>
                    {props.param.icon !== "" ? <FontAwesomeIcon icon={props.param.icon}/> : <></>}
                </div>
                <div>
                    <h6>{props.title}</h6>
                </div>
            </div>
            <div className={style}>
                {props.value}
            </div>
           
            <div className='card-body'>
                {props.body}
            </div>
        </div>

    );
};


const Home = () => {
    const { user } = useAuthenticator((context) => [context.user]);
    const [clientCount, setClientCount] = useState(0);
    const [promoCount, setPromoCount] = useState(0);
    const [couponCount, setCouponCount] = useState(0);
    const [couponExpCount, setCouponExpCount] = useState(0);
    const [couponRedeemedCount, setCouponRedeemedCount] = useState(0);


    const displayList = [
        {id:1, title:"Client Pool", body:"Total client's information", value:clientCount, class:'bg', param:{icon:faUsers, style:"blue"}},
        {id:2, title:"Promotions", body:"Total promotions", value:promoCount, class:'bg', param:{icon:faTags, style:"green"}},
        {id:3, title:"Coupons", body:"Total coupons", value:couponCount, class:'bg', param:{icon:faBarcode, style:"yellow"}},
        {id:4, title:"Coupons Redeemed", body:"How many coupons have been used", value:couponRedeemedCount, class:'bg', param:{icon:faReceipt, style:"orange"}},
        {id:5, title:"Coupons Expired", body:"How many coupons have expired", value:couponExpCount, class:'bg', param:{icon:faCalendarCheck, style:"red"}}
    ]

    const linkList = [
        {id:1, title:"ULINE", body:<img src="/images/1.png" alt="uline link" width="100%"></img>, value:"https://www.uline.com/", class:'bg', param:{style:"none", cardStyle:"link"}},
        {id:2, title:"ZURNO", body:<img src="/images/2.jpg" alt="=Zurno link" width="60%"></img>, value:"https://shop.nailsjobs.com/collections/dip-n-duo", class:'bg', param:{style:"none", cardStyle:"link"}},
        {id:3, title:"5 STAR WEBSITE", body:<img src="/images/LOGO.svg" alt="5 star link" width="50%"></img>, value:"https://www.5starnailspa-wc.com/", class:'bg', param:{style:"none", cardStyle:"link"}},
        {id:4, title:"TOWEL HUB", body:<img src="/images/4.png" alt="towelhub link" width="100%"></img>, value:"https://towelhub.com/", class:'bg', param:{style:"none", cardStyle:"link"}},

    ]

    const fetchData = useCallback(async() => {
        try {
            await DataStore.query(Client).then( res => setClientCount(res.length));
            await DataStore.query(Promotions).then( res => setPromoCount(res.length));
            await DataStore.query(Coupon).then( res => setCouponCount(res.length));
            await DataStore.query(Coupon, (coupon) => coupon.redeemed("eq", true)).then( res => setCouponRedeemedCount(res.length))
            await DataStore.query(Coupon, (coupon) => coupon.expired("eq", true)).then( res => setCouponExpCount(res.length))

        }
        catch(e){
            console.log(e)
        }

    },[])

    

    useEffect (()=> {
        fetchData();
    },[fetchData])
    

    return (
            <Authenticator>
                <div className='home-container'>
                    <div className="user-div-home">
                        <div className="ico">
                            <FontAwesomeIcon icon={faCircleUser}/>
                        </div>
                        <div className="username-home">
                            Hiya {user.attributes.preferred_username}
                        </div>
                        <button className='refresh-button' onClick={fetchData}>
                            <FontAwesomeIcon icon={faArrowsRotate}/>
                        </button>
                    </div>
                    <div className='card-container'>
                        {linkList.map((card) => <Cards key={card.id} title={card.title} body={card.body} class={card.class} value={card.value} param={card.param} func={fetchData}/>)}
                    </div>
                    <div className='card-container'>
                        {displayList.map((card) => <Cards key={card.id} title={card.title} body={card.body} class={card.class} value={card.value} param={card.param} func={fetchData}/>)}
                    </div>
                </div>
            </Authenticator>
        )
};


export default Home;