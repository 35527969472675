
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Portal from './pages/Portal';

import Home from './pages/Home';
import { Authenticator} from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import '@aws-amplify/ui-react/styles.css';
import './App.css';
import ClientPool from './pages/ClientPool';
import PromotionPool from './pages/PromotionPool';
import CouponPool from './pages/CouponPool';
import SMSForm from './components/SMSForm';

Amplify.configure(awsExports);



/** Give rise to Routes used! */
function App() {
  return (
    <div className="App">
      <Authenticator.Provider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Portal />}>
            <Route index element={<Home />} />
            <Route path="home" element={<Home />} />
            <Route path="promotion" element={<PromotionPool/>} />
            <Route path="clientpool" element={<ClientPool/>} />
            <Route path="couponpool" element={<CouponPool />} />
            <Route path="sms" element={<SMSForm />} />
          </Route>
        </Routes>
      </BrowserRouter>
      </Authenticator.Provider>
    </div>
  );
}

export default App;
